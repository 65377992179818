/* eslint-disable no-undef */
"use strict"

const PREFIX = 'participants'

export default {
  updateCurrentStep(id, step) {
    return axios.put(`${PREFIX}/update/${id}/step`, {
      'current_step': step
    })
  },

  updateDisclaimer(id) {
    return axios.put(`${PREFIX}/update/${id}/disclaimer`, {})
  }
}