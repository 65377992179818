/* eslint-disable no-undef */
"use strict"

const PREFIX = 'assets'
export default {
  generateImageBySlug(slug) {
    return axios.get(`${PREFIX}/images/${slug}`)
  },

  getImages() {
    return axios.get(`${PREFIX}/images`)
  },

  getVideos() {
    return axios.get(`${PREFIX}/videos`)
  },
  getVideoByImageTag(tag) {
    return axios.get(`${PREFIX}/video/${tag}`)
  }
}