"use strict"

import assetApi from "@/api/asset";

// ! Initial state
const state = () => ({
  images: []
})

// ! Mutations
const mutations = {
  SET_IMAGE(state, images) {
    state.images = images
  }
}

// ! Actions
const actions = {
  getImageWatermark(context) {
    return new Promise((resolve, reject) => {
      assetApi.getImages().then(res => {
        // const res = await assetApi.getImages();
        const data = res.data.data;
        let mapImages = data.map((item) => {
          item.name = `data:image/png;base64,${item.name}`;
          return item;
        });

        context.commit('SET_IMAGE', mapImages)

        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  }
}

// ! Getters
const getters = {
  images: state => state.images
}

export default {
  state,
  mutations,
  actions,
  getters
}