import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  // SECTION: public route
  {
    path: "/practical/:id",
    name: "PracticalTest",
    component: () =>
      import(/* webpackChunkName: "test" */ "../views/PracticalTest.vue"),
  }, {
    path: "/final-result/:id",
    name: "FinalResult",
    component: () =>
      import(/* webpackChunkName: "test" */ "../views/FinalResult.vue"),
  }, {
    path: "/appearance-standard",
    name: "AppearanceStandard",
    component: () =>
      import(/* webpackChunkName: "test" */ "../views/AppearanceStandard.vue"),
  },{
    path: "*",
    redirect: {
      name: "Home",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },

  // SECTION: private route
  {
    path: "/test/:typeTest",
    name: "Test",
    component: () => import(/* webpackChunkName: "test" */ "../views/Test.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/guidelines",
    name: "Guidelines",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/Guidelines.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/result",
    name: "Result",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/Result.vue"),
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  const isAuth = store.getters.isAuthenticated;
  const isParticipant = store.getters.isParticipant;
  const participant = store.getters.participant;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!isAuth) {
      next({
        name: "Login",
        query: { redirect: to.fullPath },
      });
    } else {
      if (isParticipant) {
        store
          .dispatch("getAuthenticated")
          .then((res) => {
            const CURRENT_STEP = res.participant.current_step;
            console.log(CURRENT_STEP);

            if (CURRENT_STEP === "welcome")
              next({
                name: "Home",
              });
            else if (CURRENT_STEP === "guidelines")
              next({
                name: "Guidelines",
              });
            else if (
              Array("pre-test", "post-test", "remedial").includes(CURRENT_STEP)
            )
              next({
                name: "Test",
                params: {
                  typeTest: CURRENT_STEP,
                },
              });
            else if (CURRENT_STEP === "result")
              next({
                name: "Result",
              });
          })
          .catch((error) => {
            console.log(error);

            let statusCode = error.response.status;

            if (statusCode === 401) {
              store.dispatch("logout");
              next({
                name: "Login",
              });
            }

            // if (statusCode === 403) {
            //   if (to.name === 'Finish')
            //     next()
            //   else
            //     next({
            //       name: 'Finish'
            //     })
            // } else
            //   next({
            //     name: 'Login'
            //   })
          });
      } else if (to.name === "Login") {
        let currentStep = participant.participant.current_step;
        if (currentStep === "welcome")
          next({
            name: "Home",
          });
        else if (currentStep === "guidelines")
          next({
            name: "Guidelines",
          });
        else if (
          Array("pre-test", "post-test", "remedial").includes(currentStep)
        )
          next({
            name: "Test",
            params: {
              typeTest: currentStep,
            },
          });
        else if (currentStep === "result")
          next({
            name: "Result",
          });
      } else next();
    }
  } else {
    next(); // make sure to always call next()!
  }

  // NOTE: Old way
  // if (isAuth) {
  //   console.log("authorized...");

  //   if (isParticipant) {
  //     store
  //       .dispatch("getAuthenticated")
  //       .then((res) => {
  //         const CURRENT_STEP = res.participant.current_step;
  //         console.log(CURRENT_STEP);

  //         if (CURRENT_STEP === "welcome")
  //           next({
  //             name: "Home",
  //           });
  //         else if (CURRENT_STEP === "guidelines")
  //           next({
  //             name: "Guidelines",
  //           });
  //         else if (
  //           Array("pre-test", "post-test", "remedial").includes(CURRENT_STEP)
  //         )
  //           next({
  //             name: "Test",
  //             params: {
  //               typeTest: CURRENT_STEP,
  //             },
  //           });
  //         else if (CURRENT_STEP === "result")
  //           next({
  //             name: "Result",
  //           });
  //       })
  //       .catch((error) => {
  //         console.log(error);

  //         let statusCode = error.response.status;

  //         if (statusCode === 401) {
  //           store.dispatch("logout");
  //           next({
  //             name: "Login",
  //           });
  //         }

  // if (statusCode === 403) {
  //   if (to.name === 'Finish')
  //     next()
  //   else
  //     next({
  //       name: 'Finish'
  //     })
  // } else
  //   next({
  //     name: 'Login'
  //   })
  //       });
  //   } else if (to.name === "Login") {
  //     let currentStep = participant.participant.current_step;
  //     if (currentStep === "welcome")
  //       next({
  //         name: "Home",
  //       });
  //     else if (currentStep === "guidelines")
  //       next({
  //         name: "Guidelines",
  //       });
  //     else if (
  //       Array("pre-test", "post-test", "remedial").includes(currentStep)
  //     )
  //       next({
  //         name: "Test",
  //         params: {
  //           typeTest: currentStep,
  //         },
  //       });
  //     else if (currentStep === "result")
  //       next({
  //         name: "Result",
  //       });
  //   } else next();
  // } else {
  //   console.log("unauthorized...");

  //   if (to.name === "PracticalTest" || to.name === "Login") {
  //     console.log("daddd");
  //     next();
  //     // next({ name: "Login" });
  //   } else {
  //     console.log("aaaaa");
  //     // next();
  //     next({
  //       name: "Login",
  //     });
  //   }
  // }
});

export default router;
