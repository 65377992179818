"use strict"

import participantApi from '@/api/participant'
import store from '../index'

// ! Initial state
const state = () => ({
  participant: {}
})

// ! Mutations
const mutations = {
  SET_PARTICIPANT(state, participant) {
    state.participant = participant
  },

  SET_CURRENT_STEP(state, current_step) {
    state.participant.participant.current_step = current_step
  },

  SET_STATUS(state, status) {
    state.participant.participant.status = status;
  }
}

// ! Actions
const actions = {
  setParticipant(context, payload) {
    context.commit('SET_PARTICIPANT', payload)
  },

  updateCurrentStep(context, payload) {
    return new Promise((resolve, reject) => {
      let token = store.getters.token
      participantApi.updateCurrentStep(payload.id, payload.current_step, token).then(res => {
        const data = res.data.data
        context.commit('SET_CURRENT_STEP', data)
        resolve(data)
      }).catch(err => {
        reject(err)
      })
    })
  },

  updateCurrentStepOnlyState(context, payload) {
    context.commit('SET_CURRENT_STEP', payload.current_step);
  },

  disclaimerDoesntAgree(context, payload) {
    return new Promise((resolve, reject) => {
      let token = store.getters.token
      participantApi.updateDisclaimer(payload, token).then(() => {
        store.dispatch('logout')
        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  },

  updateStatus(context, payload) {
    context.commit('SET_STATUS', payload);
  }
}

// ! Getters
const getters = {
  participant: state => state.participant,
  isParticipant: state => Object.keys(state.participant).length === 0,
}

export default {
  state,
  mutations,
  actions,
  getters
}