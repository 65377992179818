import Vue from 'vue';
import './plugins/axios'
import App from './App.vue';
import '/index.css'
import router from './router'
import store from './store'

import Cloudinary, {
  CldImage,
  CldTransformation,
  CldVideo
} from "cloudinary-vue";

import VueSweetalert2 from 'vue-sweetalert2';
import Vuelidate from 'vuelidate'

import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.use(Vuelidate)
Vue.use(VueSweetalert2);

Vue.config.productionTip = false;

Vue.use(Cloudinary, {
  configuration: {
    cloudName: "demo"
  },
  components: {
    CldImage,
    CldTransformation,
    CldVideo
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');