"use strict"
import Vue from 'vue';
import VueCookie from 'vue-cookies'
Vue.use(VueCookie)

const tokenKey = 'e-learning-token'

export default {
  getToken() {
    return Vue.$cookies.get(tokenKey) || null
  },

  setToken(token) {
    // let expires = 150 / expiresToken
    // Cookie.set(tokenKey, token, {
    //   expires: expires
    // });
    Vue.$cookies.set(tokenKey, token, '1h')
  },

  removeToken() {
    Vue.$cookies.remove(tokenKey);
  }
}