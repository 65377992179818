"use strict"

import cookies from '@/utils/cookies'
import authApi from '@/api/auth'
import store from '../index'

// Initial state
const state = () => ({
  token: cookies.getToken() || null,
})

// Mutations
const mutations = {
  SET_TOKEN(state, token) {
    state.token = token
  }
}

// Actions
const actions = {
  login(context, payload) {
    return new Promise((resolve, reject) => {
      authApi.login({
        'phone_number': payload
      }).then(res => {
        const data = res.data
        const token = data.token.token

        context.commit('SET_TOKEN', token)
        store.dispatch('setParticipant', data.data)
        cookies.setToken(token)

        resolve(data.data)
      }).catch(err => {
        reject(err)
      })
    })
  },

  getAuthenticated(context) {
    return new Promise((resolve, reject) => {
      let token = store.getters.token
      authApi.authenticated(token).then(res => {
        const data = res.data.data

        store.dispatch('setParticipant', data)
        resolve(data)
      }).catch(err => {
        reject(err)
      })
    })
  },

  logout(context) {
    context.commit('SET_TOKEN', null)
    store.dispatch('setParticipant', {})
    cookies.setToken(null)
    cookies.removeToken()
  },
}

const getters = {
  isAuthenticated: state => !!state.token,
  token: state => state.token
}

export default {
  // namespaced: true,
  state,
  mutations,
  actions,
  getters
}