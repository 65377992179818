<template>
  <div id="app">
    <Header v-show="$route.name !== 'Login'" />
    
    <main>
      <router-view></router-view>
    </main>

    <Footer />
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: "App",
  components: {
    Header: () => import('@/components/core/Header.vue'),
    Footer: () => import('@/components/core/Footer.vue')
  },
};
</script>
